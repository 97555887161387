import React, { useEffect, useState } from "react";
import { getToken, handleAuthenticationError } from "../../userAuth";
import { Card, ListGroup } from "react-bootstrap";
import { getJobStatus, getWhyError, boolPreviousJob } from "../../api/apiDNA";

export default function JobStatus({ 
    hasUploaded,
    hasResults,
    updateHasUploaded,
    updateHasResults
}) {
    const [jobStatus, setJobStatus] = useState([]);
    const [errorInfo, setErrorInfo] = useState(null);
    const [error, setError] = useState(null);
    const [isPreviousLogs, setPreviousLogs] = useState(false);

    useEffect(() => {
        async function checkPreviousJob() {
          if (!hasUploaded && !hasResults) {
            const PreviousLogs = await boolPreviousJob({ token: getToken() });
            setPreviousLogs(PreviousLogs.data.status);
          }
        }
        void checkPreviousJob();
      }, [hasUploaded, hasResults]);

    useEffect(() => {
        async function fetchJobStatus() {
            if (hasUploaded || hasResults || isPreviousLogs) {
                try {
                    const response = await getJobStatus({ token: getToken() });
                    setJobStatus(response.data);
                    setError(null);
    
                    // Check if the job was cancelled or completed
                    const lastStatus = response.data[response.data.length - 1];
                    // If 'Cancelled' find error
                    if (lastStatus.STATUS.startsWith('Cancelled')) {
                        const errorResponse = await getWhyError({ token: getToken() });
                        setErrorInfo(errorResponse.data);
                        updateHasUploaded(false);
                    }
                    // If 'Complete' ensure we update to hasResults true.
                    if (lastStatus.STATUS === 'Complete') {
                        updateHasResults(true);
                    }
    
                } catch (error) {
                    handleAuthenticationError(error);
                    setError("Failed to fetch job status. Server might be under maintenance. Try again later.");
                }
            }
        }

        if (hasUploaded && !hasResults) {
            fetchJobStatus(); // Fetch immediately when uploaded
            const intervalId = setInterval(fetchJobStatus, 60000);
            return () => clearInterval(intervalId);
          } else if (isPreviousLogs) {
            fetchJobStatus(); // Fetch once for previous logs
          }
        }, [hasUploaded, hasResults, isPreviousLogs, updateHasResults]);

    const getStatusColor = (status, index, totalStatuses) => {
        if (status.startsWith('Cancelled') || (index < totalStatuses - 1 && jobStatus[index + 1].STATUS.startsWith('Cancelled'))) {
            return 'text-danger';
        }
        if (status === 'Complete') {
            return 'text-success';
        }
        if (index === totalStatuses - 1 && !status.startsWith('Cancelled')) {
            return 'text-primary';
        }
        return 'text-success';
    };

    const getStatusEmoji = (status, index, totalStatuses) => {
        if (status.startsWith('Cancelled') || (index < totalStatuses - 1 && jobStatus[index + 1].STATUS.startsWith('Cancelled'))) {
            return '💀';
        }
        if (status === 'Complete') {
            return '✅';
        }
        if (index === totalStatuses - 1 && !status.startsWith('Cancelled')) {
            return '⌛';
        }
        return '✅';
    };

    return (
        <Card className="mt-3">
            <Card.Header>{isPreviousLogs ? "Previous Job Status" : "Job Status"}</Card.Header>
            <Card.Body>
                {error ? (
                    <Card.Text className="text-danger">{error}</Card.Text>
                ) : (
                    <ListGroup variant="flush">
                        {jobStatus.map((status, index) => (
                            <ListGroup.Item
                                key={index}
                                className={getStatusColor(status.STATUS, index, jobStatus.length)}
                            >
                                <strong>{status.STATUS}</strong>: {new Date(status.ENTRY_TIME).toLocaleString()}
                                <p className="mb-0 mt-1">
                                    <small>
                                        {getStatusEmoji(status.STATUS, index, jobStatus.length)} {status.DESCRIPTION}
                                    </small>
                                </p>
                                {status.STATUS.startsWith('Cancelled') && errorInfo && (
                                    <p className="mb-0 mt-1">
                                        <small>
                                            This was a {errorInfo.ERROR_TYPE.toLowerCase()}. {errorInfo.DESCRIPTION_ERROR}
                                        </small>
                                    </p>
                                )}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
            </Card.Body>
        </Card>
    );
}