import {axios, backendUri} from "./config";

export const uploadDNAFile = (formData) => axios.post(`${backendUri}/uploadDNA`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const hasUploadedDNAFile = (props) => axios.post(`${backendUri}/hasUploadedDNA`, props);

export const cancelDNAFile = (props) => axios.post(`${backendUri}/DNAcancel`, props);

export const getGenomicResultsApi = (props) => axios.post(`${backendUri}/getGenomicResults`, props);

export const getJobStatus = (props) => axios.post(`${backendUri}/getJobStatus`, props);

export const getWhyError = (props) => axios.post(`${backendUri}/getWhyError`, props);

export const isResolved = (props) => axios.post(`${backendUri}/isResolved`, props);

export const boolPreviousJob = (props) => axios.post(`${backendUri}/boolPreviousJob`, props);